import { useState, useRef, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useChain, useTrail, useTransition, animated } from 'react-spring'
import styled from '@emotion/styled'

// import logo from '../../images/logo.svg'
import { history, breakpoint, fluidSizing } from '../../utils'
import LangSwitcher from '../LangSwitcher'

const menus = [
  {
    to: '/',
    label: 'Home'
  },
  {
    to: '/about-us',
    label: 'About Us'
  },
  {
    to: '/brand',
    label: 'Brand'
  },
  {
    to: '/menu',
    label: 'Menu'
  },
  {
    to: '/news-promotion',
    label: 'News & Promotion'
  },
  {
    to: '/where-to-buy',
    label: 'Where to buy'
  },
  {
    to: 'https://www.benas-spread.com/e-brochure',
    label: 'E-Brochure',
  },
  {
    to: '/faq',
    label: 'FAQ & Reviews'
  },
  {
    to: '/contact-us',
    label: 'Contact Us'
  }
]

const Navigation = (props) => {
  const [showNav, setShowNav] = useState(false)

  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  // useEffect(() => {
  //   if (showNav) {
  //     document.body.style.position = 'fixed'
  //     document.body.style.top = `-${window.scrollY}px`
  //   } else {
  //     const scrollY = document.body.style.top
  //     document.body.style.position = ''
  //     document.body.style.top = ''
  //     window.scrollTo(0, parseInt(scrollY || '0') * -1)
  //   }
  // }, [showNav])

  const menuRef = useRef()
  const menuItemRef = useRef()

  const trail = useTrail(menus.length, {
    ref: menuItemRef,
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: showNav ? 1 : 0
  })

  const menuTransition = useTransition(showNav, null, {
    ref: menuRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useChain(showNav ? [menuRef, menuItemRef] : [menuItemRef, menuRef], [0, 0.1])

  const handleToggleMenu = () => {
    setShowNav(!showNav)
  }

  const renderLink = (linkItem, i) => {
    if (linkItem.to && linkItem.to.indexOf("http") >= 0) {
      return (
        <a key={i} href={linkItem.to} target="_blank">
          {`${linkItem.label}`}
        </a>
      )
    }
    return (
      <NavLink key={i} to={linkItem.to} exact={i === 0}>
        {linkItem.label}
      </NavLink>
    )
  }

  return (
    <Style menuOpen={showNav}>
      <div className='box-header'>
        <div className='logo' onClick={() => setShowNav(false)}>
          <img className='bg-logo' src='/images/bg-logo.png' alt='' />
          <img
            className='img-logo'
            src='/images/logo-benas.svg'
            alt=''
            onClick={() => history.push('/')}
          />
        </div>

        <div className='nav'>
          {menus.map((item, i) => (
            <div className='nav-item' key={i}>
              {renderLink(item, i)}
            </div>
          ))}
        </div>

        <div className={`nav-mobile-toggle ${showNav && 'open'}`} onClick={handleToggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <LangSwitcher />
      </div>

      {menuTransition.map(
        (menu) =>
          menu.item && (
            <animated.div className='nav-mobile' style={menu.props}>
              <img
                src='/images/ico-close-modal.webp'
                alt=''
                className='btn-close'
                onClick={() => setShowNav(false)}
              />
              {trail.map(({ x, height, ...rest }, index) => {
                const item = menus[index]
                return (
                  <animated.div
                    key={index}
                    className='nav-item'
                    style={rest}
                    onClick={handleToggleMenu}
                  >
                    {renderLink(item, index)}
                    {/* <NavLink exact to={item.to}>
                      {item.label}
                    </NavLink> */}
                  </animated.div>
                )
              })}
              <LangSwitcher />
            </animated.div>
          )
      )}
    </Style>
  )
}

export default Navigation

const Style = styled.div`
  label: Navigation;

  .box-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: ${(p) => (p.menuOpen ? 0 : 'auton')};
    z-index: 2;
    display: flex;
    transform: translateZ(0);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 190px;
      opacity: 0.5;
      z-index: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.28363095238095233) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    > .logo {
      position: relative;
      z-index: 1;
      flex-shrink: 0;

      > .bg-logo {
        margin-left: -40px;
        ${fluidSizing('width', { SM: 130, T: 230, D: 430 })}
      }
      > .img-logo {
        position: absolute;
        ${fluidSizing('width', { SM: 64, T: 64, D: 128 })}
        ${fluidSizing('left', { SM: 15, T: 20, D: 57 })}
        top: 24px;
        /* left: 57px; */
        display: block;
        cursor: pointer;
      }
    }

    > .nav-mobile {
      display: none;
    }

    > .nav {
      position: relative;
      z-index: 1;
      margin-top: 25px;
      margin-right: 140px;
    }

    > .nav-mobile-toggle {
      display: none;
      width: 30px;
      height: 20px;
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 11px;
      cursor: pointer;

      > span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: all 0.25s ease-in-out;
      }

      > span:nth-of-type(1) {
        top: 0px;
        transform-origin: left center;
      }

      > span:nth-of-type(2) {
        top: 50%;
        margin-top: -1px;
        transform-origin: left center;
      }

      > span:nth-of-type(3) {
        bottom: 0;
        transform-origin: left center;
      }
    }

    .language-switcher {
      position: absolute;
      top: 20px;
      right: 57px;
      z-index: 1;
    }
  }

  .nav,
  .nav-mobile {
    margin-left: auto;

    > .nav-item {
      display: inline-block;

      a {
        color: #fff;
        display: block;
        padding: 2px 0;
        letter-spacing: 1.6px;
        transition: all 0.3s ease-in-out;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 2px solid transparent;

        &.active {
          color: #993486;
          border-bottom: 2px solid #993486;
        }

        ${fluidSizing('font-size', { SM: 10, T: 10, D: 12 })}
        ${fluidSizing('margin-left', { SM: 10, T: 10, D: 10 })}
        ${fluidSizing('margin-right', { SM: 10, T: 10, D: 10 })}
      }
    }
  }

  ${breakpoint('1000px')} {
    .box-header {
      > .nav {
        display: none;
      }

      > .nav-mobile-toggle {
        display: inline-block;
      }

      .language-switcher {
        top: 15px;
        display: flex;
        flex-direction: row-reverse;

        .flag {
          margin-left: 6px;
        }
      }
    }

    > .nav-mobile {
      display: flex;
      position: fixed;
      z-index: 3;
      background: rgb(0 0 0 / 80%);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .btn-close {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 23px;
        cursor: pointer;
      }

      > .nav-item {
        margin: 15px 0;
      }
    }
  }

  ${breakpoint('M')} {
    .box-header {
      &:after {
        height: 60px;
      }

      > .logo {
        margin-left: 14px;

        .bg-logo {
          width: 130px;
          margin: 0;
        }
        .img-logo {
          width: 62px;
          top: 10px;
          left: 22px;
        }
      }
    }
  }
`
