import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

import { breakpoint } from '../../utils'
import Follow from '../Follow'

const menus = [
  {
    to: '/',
    label: 'Home'
  },
  {
    to: '/about-us',
    label: 'About Us'
  },
  {
    to: '/brand',
    label: 'Brand'
  },
  {
    to: '/menu',
    label: 'Menu'
  },
  {
    to: '/news-promotion',
    label: 'News & Promotion'
  },
  {
    to: '/where-to-buy',
    label: 'Where to buy'
  },
  {
    to: 'https://www.benas-spread.com/e-brochure',
    label: 'E-Brochure',
  },
  {
    to: '/faq',
    label: 'FAQ & Reviews'
  },
  {
    to: '/contact-us',
    label: 'Contact Us'
  },
  {
    to: '/privacy-policy',
    label: 'Privacy Policy'
  }
]

const Footer = () => {
  // const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return null
  // const { content } = appData

  const renderLink = (linkItem, i) => {
    if (linkItem.to && linkItem.to.indexOf("http") >= 0) {
      return (
        <a key={i} href={linkItem.to} target="_blank">
          {linkItem.label}
        </a>
      )
    }
    return (
      <NavLink key={i} to={linkItem.to} exact={i === 0}>
        {linkItem.label}
      </NavLink>
    )
  }

  return (
    <Style>
      <img src='/images/logo-footer.webp' alt='' className='footer-logo' />
      <div className='box-body'>
        <div className='row'>
          <div className='D-10 M-8 SM-6'>
            <div className='footer-navigation'>
              {menus.map((item, i) => renderLink(item, i))}
            </div>
          </div>
          <div className='D-2 M-4 SM-6'>
            <Follow titleColor='#fff' />
          </div>
        </div>
      </div>
      <div className='copyright'>
        COPYRIGHT © 2021 CHIANGMAI FROZEN FOODS PUBLIC COMPANY LIMITED. All rights reserved
      </div>
    </Style>
  )
}

export const paddingFooter = css`
  &:after {
    content: '';
    display: block;
    height: 276px;
  }

  ${breakpoint('L')} {
    &:after {
      height: 325px;
    }
  }

  ${breakpoint('570px')} {
    &:after {
      height: 430px;
    }
  }
`

const Style = styled('div')`
  label: Footer;

  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgb(0 0 0 / 88%);
  color: #fff;
  padding: 60px;

  .copyright {
    margin-top: 40px;
    font-family: 'Kanit';
    font-size: 10px;
    letter-spacing: 0.54px;
    font-weight: 300;
    line-height: 1.43;
  }

  .footer-logo {
    width: 104px;
    display: block;
  }

  .box-body {
  }

  .footer-navigation {
    margin-top: 36px;

    a {
      display: inline-block;
      color: #fff;
      margin-bottom: 8px;
      font-size: 13px;
      text-decoration: none;
      text-transform: uppercase;

      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }

  .social-link {
    a {
      color: #fff;
      transition: all 0.3s ease-in-out;
      margin-right: 10px;

      &:hover {
        color: #a98e55;
      }
    }
  }

  ${breakpoint('L')} {
    padding: 20px;

    .box-body {
      margin-top: 36px;
    }

    .footer-navigation {
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;

      a {
        display: block;
        width: 50%;

        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  ${breakpoint('570px')} {
    .footer-navigation {
      display: block;
      a {
        width: 100%;
        margin-bottom: 20px;
        font-size: 9px;
      }
    }

    .copyright {
      text-align: center;
      margin-top: 20px;
    }
  }
`

export default Footer
