import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
// import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'

import Welcome from './Welcome'
import Introduction from './Introduction'
import WhereToBuy from './WhereToBuy'
import MenuSlider from './MenuSlider'
import NewsAndPromotionSlider from './NewsAndPromotionSlider'

const Home = () => {
  const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return <Style />
  const { whereToBuy } = appData

  return (
    <Style>
      <Helmet>
        <title>Benas</title>
        <meta name='title' content='Benas' />
        <meta name='description' content='' />
      </Helmet>
      <Welcome />
      <Introduction />
      <MenuSlider />
      <NewsAndPromotionSlider isHome />
      <WhereToBuy items={whereToBuy} />
    </Style>
  )
}

const Style = styled('div')`
  min-height: 100vh;
`

export default Home
